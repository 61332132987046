import React, {useContext} from "react";
import {CompanyContext} from "./CompanyContext";
import {Navbar, Nav, Container} from 'react-bootstrap'

export function SimpleNavbar(){
    const company = useContext(CompanyContext);

    return(
        <div>
            <Navbar collapseOnSelect expand="lg" variant="light" style={{background: company.color}}>
                <Container>
                    <Navbar.Brand href="#home">{company.name} Intranet</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="#home">Accountverwaltung</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>
    )
}
