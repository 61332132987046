import React, {useContext, useState} from "react";
import "../style/LDAP.css";
import {CompanyContext} from "./CompanyContext";
import PasswordStrengthBar from "react-password-strength-bar/dist";
import axios from "axios";
import * as Swal from "sweetalert2";
export const api_endpoint_url = "/api/handler.php";

export function LDAP(){
    const company = useContext(CompanyContext);
    // When user is logged in:
    const [login, setLogin] = useState(false);
    const [user, setUser] = useState({name: "no_name"});
    // For password page:
    const [password, setPassword] = useState("");
    const [helptext, setHelptext] = useState("");
    const [safepw, setSafePw] = useState(false);
    const [pwident, setPwIdent] = useState("");

    //Config for password strength bar:
    let scoreWords = ['Sehr schwach!', 'Schwach!', 'Okay', 'Gut', 'Sicher!'];
    let shortScoreWord = "Zu kurz!";
    let minLength = 8;

    function onScoreChangeHandler(score, feedback){
        setHelptext("");
        if(feedback.warning !== undefined){
            if(feedback.warning === "This is similar to a commonly used password"){
                setHelptext("Ihr Passwort ist identisch zu einem der am meisten verwendeten Passwörter!");
            }
            if(feedback.warning === "This is a top-10 common password"){
                setHelptext("Ihr Passwort ist eins der 10 am meisten verwendeten Passwörter weltweit!");
            }
        }
        if(score > 2){
            setSafePw(true);
        }
        else{
            setSafePw(false);
        }
    }

    function onPwCheckChange(e){
        setPwIdent(e.target.value);
    }

    async function btnOnClickPWChange(e){
        e.preventDefault();
        console.log("click")
        //BACKEND CALL CHANGE PW
        try{
            const obj = {
                method: "changepw",
                password: password
            };
            const formData = new FormData();
            formData.append("request", JSON.stringify(obj));
            const promise = axios.post(
                api_endpoint_url,
                formData, {withCredentials: true}
            );
            const response = await promise;

            if (response.data.status === "failed") {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Das hat leider nicht geklappt!',
                        text: 'Passwortänderung fehlgeschlagen'
                    }
                );
            } else {
                Swal.fire(
                    {
                        icon: 'success',
                        title: 'Das hat geklappt!',
                        text: 'Passwortänderung erfolgreich'
                    }
                );
                setLogin(false);
            }
        }catch (e) {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Das hat leider nicht geklappt!',
                    text: 'Passwortänderung fehlgeschlagen'
                }
            );
        }
    }

    async function btnOnClickLogin(e){
        e.preventDefault();
        //BACKEND CALL LOGIN
        let usernamea = document.getElementById("username").value;
        let passworda = document.getElementById("passwordl").value;
        try{
            const obj = {
                method: "login",
                username: usernamea,
                password: passworda
            };
            const formData = new FormData();
            formData.append("request", JSON.stringify(obj));
            const promise = axios.post(
                api_endpoint_url,
                formData, {withCredentials: true}
            );
            const response = await promise;

            if (response.data.status === "failed") {
                Swal.fire(
                    {
                        icon: 'error',
                        title: 'Das hat leider nicht geklappt!',
                        text: 'Login fehlgeschlagen'
                    }
                );
            } else {
                setLogin(true);
                setUser(usernamea);
            }
        }catch (e) {
            Swal.fire(
                {
                    icon: 'error',
                    title: 'Das hat leider nicht geklappt!',
                    text: 'Login fehlgeschlagen'
                }
            );
        }

    }

    if(login !== false){
        // Change PW Mask:
        return(
            <div className={"container"} style={{marginTop: 40}}>
                <div className={"row"}>
                    <div className={"col-md-6 mx-auto formbox"}>
                        <h3 align={"center"}>Accountverwaltung</h3>
                        <br />
                        <p align={"center"}>Auf dieser Website haben Sie die Möglichkeit Ihr Passwort für Ihren {company.name} Account zu ändern. Dies betrifft alle Intranet und E-Mail Dienste, sowie Ihren VPN Zugang.</p>

                        <br />
                        <div className="form-group">
                            <label htmlFor="password" style={{marginBottom: 10}}>Ihr neues Passwort</label>
                            <input type="password" className="form-control" id="password" onChange={(e) => setPassword(e.target.value)} placeholder="Passwort" />
                            <PasswordStrengthBar password={password} scoreWords={scoreWords} shortScoreWord={shortScoreWord} minLength={minLength} onChangeScore={onScoreChangeHandler}/>
                        </div>
                        <small style={{color: "red"}}>{helptext}</small>
                        <br />
                        <br />
                        <div className="form-group">
                            <label htmlFor="password" style={{marginBottom: 10}}>Passwort wiederholen</label>
                            <input type="password" className="form-control" id="password" placeholder="Passwort" onChange={(e) => onPwCheckChange(e)} />
                        </div>
                        <br />
                        <small style={{color: "red"}}>{pwident===password?"":"Passwörter sind nicht identisch"}</small>
                        <br />
                        <br />
                        <button className={"btn btn-warning"} disabled={!(safepw) || !(pwident === password)} onClick={(e) => btnOnClickPWChange(e)}>Passwort ändern</button>
                    </div>
                </div>
            </div>
        )
    }
    else{
        // Login Mask:
        return(
            <div className={"container"} style={{marginTop: 40}}>
                <div className={"col-md-6 mx-auto formbox"}>
                    <h3 align={"center"}>Accountverwaltung</h3>
                    <br />
                    <div className="form-group">
                        <label htmlFor="username" style={{marginBottom: 10}}>Ihr {company.name} Benutzername</label>
                        <input type="text" className="form-control" id="username" aria-describedby="accHelp" placeholder="Benutzername" />
                        <small id="accHelp" className="form-text text-muted">Den Benutzernamen haben Sie mitsamt eines Passworts von der IT-Abteilung erhalten</small>
                    </div>
                    <br />
                    <div className="form-group">
                        <label htmlFor="passwordl" style={{marginBottom: 10}}>Passwort</label>
                        <input type="password" className="form-control" id="passwordl" placeholder="Passwort" />
                    </div>

                    <br />
                    <button className={"btn btn-success"} onClick={(e) => btnOnClickLogin(e)}>Anmelden</button>
                </div>

            </div>
        )
    }

}
