import React, {useEffect, useState} from "react";

export const CompanyContext = React.createContext(undefined);

export const CompanyContextProvider = ({children}) => {
    const [company, setCompany] = useState(undefined);
    useEffect(() => {
        fetch('settings.json',{
            headers : {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((r) => r.json())
            .then((data) =>{
                let settings = data;
                let co = settings.companyName;
                if(co === "EWF"){
                    setCompany({
                        name: "EWF Energy",
                        image: "logo_ewf.png",
                        color: "#588234",
                        gradientMid: "#76A252",
                        gradientEnd: "#A2C783",
                        backcolor: "#E1E1E1",
                    })
                }
                else{
                    setCompany({
                        name: "MJ-Projektservice",
                        image: "logo_mj.png",
                        color: "#fcd905",
                        gradientMid: "#fcd905",
                        gradientEnd: "#fcd905",
                        backcolor: "#000000",
                    })
                }
            });
    }, []);
    if(company === undefined){
        return(<div>Loading...</div>);
    }
    else{
        return(
            <CompanyContext.Provider value={company}>
                {children}
            </CompanyContext.Provider>
        )
    }
}
