import React from "react";

import 'bootstrap/dist/css/bootstrap.css';

import {CompanyContextProvider} from "./assets/components/CompanyContext";
import {SimpleNavbar} from "./assets/components/SimpleNavbar";
import {LDAP} from "./assets/components/LDAP";


function App() {
  return (
    <div className="App">
      <CompanyContextProvider>
        <SimpleNavbar/>
        <LDAP />
      </CompanyContextProvider>
    </div>
  );
}

export default App;
